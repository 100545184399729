import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form'


// Styles
import {MenuItem, TextField} from "@material-ui/core";


const InputSelect = (props) => {

    const {
        label,
        name,
        options,
        control,
        handleChange,
        required = false
    } = props;

    return (
        <Controller
            rules={{required: required}}
            control={control}
            name={name}
            defaultValue={''}
            render={({field: {onChange, value}}) => (
                <TextField select
                           label={label}
                           name={name}
                           value={value}
                           onChange={(item) => {
                               if (handleChange) handleChange(item)
                               onChange(item)
                           }}>
                    {
                        options && options.map((item,i) => <MenuItem key={i.toString()} value={item.value}>{item.label}</MenuItem>
                        )
                    }
                </TextField>
            )}
        />
    );
}


// PropTypes
InputSelect.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    handleChange: PropTypes.func,
    required: PropTypes.bool
}


export default InputSelect;
