import {axiosForm} from '../index'

export const getToken = () => {
    return axiosForm({
        method: 'post',
        url: '/api/procolombia/createToken',
        data:{
            email: process.env.REACT_APP_USER_TOKEN,
            password: process.env.REACT_APP_PASS_TOKEN
        }
    })
}

export const getDataForm = ({lang,event_url}) => {
    return axiosForm({
        method: 'get',
        url: '/api/procolombia/getParametersPreRegistration',
        params:{
          event_url
        },
        headers: {
            'Accept-Language' : lang
        }
    })
}

export const postForm = (data) => {
    return axiosForm({
        method: 'post',
        url: '/api/procolombia/preRegistration',
        data
    })
};

export const verifyForm = (data) => {
    return axiosForm({
        method: 'post',
        url:'api/procolombia/confirmMail',
        data
    });
};

export const sendEmail = (data) => {
    return axiosForm({
        method: 'post',
        url:'api/procolombia/resendPassbook',
        data
    });
};

export const formConfig = ({event_url}) =>{
    return axiosForm({
        method:'get',
        url:'api/procolombia/getParametersPreRegistration'
    });
}
