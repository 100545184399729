import React from "react";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import useComponents from "../../components/inputs";

// Components
import { Button } from "../../components/buttons";
import Modal from "../../components/Modal";
import { Image } from "../../components/Image/Image";

//Assets

import imageBackground from "../../assets/img/bg-mosaico-registro-lg.png";
import imgBackgroundMobile from "../../assets/img/bg-mosaico-registro-pq.png";
import imageLogo from "../../assets/img/logo-marca-colombia.png";

//Hook
import { useFormApp } from "./hook";
import {
  StyledCol,
  StyledGrid,
  StyledTerms,
  StyledWrapper,
} from "./form.styles";
import SpinnerLoadingIndicator from "../../components/SpinnerLoading";
import parse from "html-react-parser";
import {usePromiseTracker} from "react-promise-tracker";

const FormScreen = () => {
  // Inputs
  const { Select, Checkbox, Input, SelectLanguage } = useComponents();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    countries,
    languages,
    typeParticipations,
    mainInterests,
    colombiaKnows,
    language,
    company,
    desc,
    modal,
    mainInterest,
    openModal,
    typeParticip,
    submitForm,
    sendEmail,
    companyError,
    companyOnChange,
    descOnChange,
    descError,
    setValEmail,
    valEmail,
    changeLanguage,
    city,
    principalInterest,
    participationType,
    knowCountry,
    lan,
    footerTerms,
    country,
    titles,
  } = useFormApp(setValue);

  const errorStyle = {
    color: "red",
    fontSize: "small",
    marginBottom: "5px",
    fontFamily: "RobotoRegular",
  };

  const errorStyleWhite = {
    color: "white",
    fontSize: "small",
    marginBottom: "5px",
    fontFamily: "RobotoRegular",
  };

  const {promiseInProgress} = usePromiseTracker()

  if(promiseInProgress){
    return <SpinnerLoadingIndicator/>
  }
  return (
    <>
      <div>
        <SelectLanguage changeLanguage={changeLanguage} lan={lan} />
      </div>

      <Modal
        open={modal.open}
        message={modal.message}
        handleClick={modal.handleClick}
        title={modal.title}
        data={modal.res}
        sendOption={sendEmail}
      />

      <Image
        imageSrc={imageLogo}
        className="relative block mx-auto mt-20 mb-12 sm:my-12 w-56 md:w-auto"
      />

      <form
        className="relative"
        onSubmit={handleSubmit(submitForm)}
        id={"form_procolombia"}
      >
        <Image
          imageSrc={imageBackground}
          className="absolute w-full mt-12 hidden sm:block"
        />
        <Image
          imageSrc={imgBackgroundMobile}
          className="absolute w-full mt-12 block sm:hidden"
        />

        <StyledWrapper className="w-10/12 max-w-3xl mx-auto">
          {titles && (
            <>
              <h1 className="text-lg font-sansRegular text-secondary text-center leading-tight">
                {titles.title}
              </h1>
              <h2 className="text-secondary font-sansBold text-lg text-center leading-tight">
                {titles.subtitle}
              </h2>
            </>
          )}
          <StyledGrid>
            {/* Name */}
            <StyledCol>
              <Input
                control={control}
                name={"name"}
                label={language.name}
                required={true}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={() => <p style={errorStyle}>{language.text_error}</p>}
              />
            </StyledCol>

            {/* Apellido */}
            <StyledCol>
              <Input
                control={control}
                name={"surname"}
                label={language.lastname}
                required={true}
              />
              <ErrorMessage
                errors={errors}
                name="surname"
                render={() => <p style={errorStyle}>{language.text_error}</p>}
              />
            </StyledCol>

            {/* Email */}
            <StyledCol>
              <Input
                control={control}
                name={"email"}
                label={language.email}
                required={true}
                rules={{
                  validate: {
                    email: (text) => {
                      const re =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      setValEmail(!re.test(String(text).toLowerCase()));
                      return re.test(String(text).toLowerCase());
                    },
                  },
                }}
              />
              {valEmail && (
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={() => <p style={errorStyle}>{language.mail_valid}</p>}
                />
              )}
              {!valEmail && (
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={() => <p style={errorStyle}>{language.text_error}</p>}
                />
              )}
            </StyledCol>

            {/* Conoce Colombia */}
            {knowCountry && (
              <StyledCol>
                <Select
                  control={control}
                  label={language.know}
                  name={"colombia_know_id"}
                  options={colombiaKnows}
                  required={true}
                />
                <ErrorMessage
                  errors={errors}
                  name="colombia_know_id"
                  render={() => <p style={errorStyle}>{language.text_error}</p>}
                />
              </StyledCol>
            )}
            {/* País */}
            {country && (
              <StyledCol>
                <Select
                  control={control}
                  label={language.country}
                  name={"country_id"}
                  options={countries}
                  required={true}
                />
                <ErrorMessage
                  errors={errors}
                  name="country_id"
                  render={() => <p style={errorStyle}>{language.text_error}</p>}
                />
              </StyledCol>
            )}
            {/* Ciudad */}
            {city && (
              <StyledCol>
                <Input
                  control={control}
                  name={"town"}
                  label={language.city}
                  required={true}
                />
                <ErrorMessage
                  errors={errors}
                  name="town"
                  render={() => <p style={errorStyle}>{language.text_error}</p>}
                />
              </StyledCol>
            )}

            {/* Tipo de participación */}
            {participationType && (
              <>
                <StyledCol className="lg:mt-2">
                  <Select
                    control={control}
                    label={language.participation}
                    name={"type_participation_id"}
                    options={typeParticipations}
                    required={true}
                    handleChange={typeParticip}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="type_participation_id"
                    render={() => (
                      <p style={errorStyle}>{language.text_error}</p>
                    )}
                  />
                </StyledCol>
                {/* Empresa */}
                <StyledCol className="lg:mt-2">
                  {company && (
                    <Input
                      control={control}
                      name={"company"}
                      label={language.company}
                      required={false}
                      onCustomChange={companyOnChange}
                    />
                  )}
                  {company && companyError && (
                    <p style={errorStyle}>{language.text_error}</p>
                  )}
                </StyledCol>
              </>
            )}

            {principalInterest && (
              <>
                {/* Principal interes */}
                <StyledCol>
                  <Select
                    control={control}
                    label={language.interest}
                    name={"main_interest_id"}
                    options={mainInterests}
                    required={true}
                    handleChange={mainInterest}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="main_interest_id"
                    render={() => (
                      <p style={errorStyle}>{language.text_error}</p>
                    )}
                  />
                </StyledCol>

                {/* Descripción interes */}
                <StyledCol>
                  {desc && (
                    <Input
                      control={control}
                      name={"describe_interest"}
                      label={language.interest_placeholder}
                      required={false}
                      onCustomChange={descOnChange}
                    />
                  )}
                  {desc && descError && (
                    <p style={errorStyle}>{language.text_error}</p>
                  )}
                </StyledCol>
              </>
            )}

            <StyledCol className="lg:mt-2">
              <Select
                control={control}
                label={language.language}
                name={"language_information_id"}
                options={languages}
                required={true}
              />
              <ErrorMessage
                errors={errors}
                name="language_information_id"
                render={() => <p style={errorStyle}>{language.text_error}</p>}
              />
            </StyledCol>
          </StyledGrid>
          <Button
            type="submit"
            text={language.button_text}
            className="bg-secondary text-yellow uppercase font-sansBold text-xl py-1 w-48 bottom-0 absolute"
          />
        </StyledWrapper>

        {/* Términos */}

        <StyledTerms className="mt-12 mb-12 max-w-2xl mx-auto px-6 sm:px-0">
          <Checkbox
            type="checkbox"
            name="terms"
            text={language.term}
            onClick={() => openModal(false)}
            control={control}
            required={true}
            textLink={language.link_term}
            id={"terms"}
            url="/"
          />
          <ErrorMessage
            errors={errors}
            name="terms"
            render={() => <p style={errorStyleWhite}>{language.text_error}</p>}
          />
          <div className="sm:text-center my-4">
            <p className="text-white font-sansRegular leading-tight text-xs">
              {parse(footerTerms)}
            </p>
          </div>
          <Checkbox
            control={control}
            required={false}
            type="checkbox"
            name="politics"
            onClick={() => openModal(true)}
            text={language.politics}
            textLink={language.link_politics}
            id={"politics"}
            url="/"
          />
          <ErrorMessage
            errors={errors}
            name="politics"
            render={() => <p style={errorStyleWhite}>{language.text_error}</p>}
          />
        </StyledTerms>
      </form>
    </>
  );
};

export default FormScreen;
