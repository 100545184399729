import {createSelector} from "../createSelector";


export const useSelectorForm = () =>{

    const selectorConfig = createSelector(
        state => state.dataForm,
        dataForm => dataForm.config
    );

    const selectorFooterTermsByIdLang = createSelector(
        state => state.dataForm,
        state => state.form,
        (dataForm,form) => {
            const {config} = dataForm;
            const {footerTerms} = config;
            const { id } = form;
            const termsObj = footerTerms.find(f => f.idLan === id);
            return termsObj ? termsObj.html : ''
        }
    );

    const selectorTerms = createSelector(
        state => state.dataForm,
        state => state.form,
        (dataForm,form) => {
            const {message} = dataForm;
            const {termAndConditions} = message;
            const { id } = form;
            return termAndConditions.find(f => f.language_id === id);
        }
    );
    const selectorTitles = createSelector(
        state => state.dataForm,
        state => state.form,
        (dataForm,form) => {
            const {config} = dataForm;
            const { eventTitles } = config;
            const {id} = form;
            if(eventTitles){
                return eventTitles.find(f => f.language_id === id);
            }

        }
    )

    return {
        selectorConfig,
        selectorFooterTermsByIdLang,
        selectorTerms,
        selectorTitles
    };
};
