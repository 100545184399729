import styled from 'styled-components';

export const SelectDefaultStyle = styled.div` 
  position: absolute;
  right: 10px;
  top: 0;
  &:hover .dropdown-content {
    display: block;
  }
  .dropbtn {
    background-color: #fdf5e9;
    border: none;
    color: #ff4a53;
    cursor: pointer;
    margin-top: 10px;
    padding: 0px 15px 5px;
    text-transform: capitalize;
    font-size: 16px;
    &:focus {
      outline: none;
    }
    &::after {
      content: url('/sort-down.svg');
      display: inline-block;
      margin-left: 10px;
      width: 15px;
    }
  }
  .dropdown-content {
    background-color: white;
    display: none;
    right: 0;
    padding: 10px;
    position: absolute;
    z-index: 1;
    & div {
      color: #ff4a53;
      cursor: pointer;
      padding: 2px 10px;
      text-align: center;
      text-transform: capitalize;
      font-size: 16px;
    }
  }
`;