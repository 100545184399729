import {createSelector} from '../../selectors/createSelector';


export const languageSelector = createSelector(
    state => state.translate,
    translate => translate
);

export const formSelector = createSelector(
    state => state.form,
    form => form
);

export const isFetchingSelector = createSelector(
    state => state.resendEmail.isFetching,
    isFetching => isFetching
);


export const listSelector = createSelector(
    state => state.dataForm,
    (dataForm) => {

        const countries = dataForm.message.countries.map(item=>({
            value: item.id, label: item.name
        }))
        const languages = dataForm.message.languages.map(item => ({
            value: item.id, label: item.name
        }))
        const typeParticipations = dataForm.message.typeParticipations.map((item,index) => ({
            value: item.id, label: item.name, original_value: item.original_value
        }))
        const mainInterests = dataForm.message.mainInterests.map((item,index) => ({
            value: item.id, label: item.name, original_value: item.original_value
        }))
        const colombiaKnows = dataForm.message.colombiaKnows.map(item => ({
            value: item.id, label: item.name
        }))

        const tyc = dataForm.message.termAndConditions[0]

        return {
            countries,
            languages,
            typeParticipations,
            mainInterests,
            colombiaKnows,
            tyc
        }
    }
);
