import {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom';
import {
    isFetchingSelector,
    languageSelector,
    listSelector,
    formSelector
} from '../selectors'
import { useDispatch} from 'react-redux'
import {
    actSetLanguage,
    actGetToken,
    actGetDataForm,
    actPostForm,
    actLangForm, actSendEmail
} from '../../../actions/form';


import {useSelectors} from "../../selectors";

export const useFormApp = (setValue) => {
    const {useSelector,useSelectorForm} = useSelectors();
    const {id} = useParams();
    const dispatch = useDispatch();
    const {language} = useSelector(languageSelector);
    const {lang} = useSelector(formSelector);
    const isFetching = useSelector(isFetchingSelector);
    const {selectorConfig,selectorFooterTermsByIdLang,selectorTerms,selectorTitles} = useSelectorForm();
    const terms = useSelector(selectorTerms);
    const {inputs:inputsConfig,languages:lan, event} = useSelector(selectorConfig);
    const { knowCountry,city,participationType, principalInterest, country } = inputsConfig;
    const titles = useSelector(selectorTitles);
    const footerTerms = useSelector(selectorFooterTermsByIdLang);

    const {
        countries,
        languages,
        typeParticipations,
        mainInterests,
        colombiaKnows,
        tyc
    } = useSelector(listSelector);
    const [desc, setDesc] = useState(false)
    const [company, setCompany] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [descError, setDescError] = useState(false);
    const [email, setEmail] = useState(null);
    const [response, setResponse] = useState(null);
    const [valEmail,setValEmail] = useState(false)

    const [modal, setModal] = useState({
        open: false,
        message: '',
        title: '',
        handleClick: () => {
        }
    });


    const changeLanguage = (e) => {
        dispatch(actLangForm(e))
        dispatch(actGetDataForm({lang:e.code,event_url:id}))
        dispatch(actSetLanguage(e.code))
    };

    const companyOnChange = (data) => {
        if(data !== ''){
            setCompanyError(false);
        }
    };
    const descOnChange = (data) => {
        if(data !== ''){
            setDescError(false);
        }
    };

    const submitForm = (data) => {
        const {email, company:auxCompany, describe_interest} = data;

        if(company && auxCompany === ''){
            setCompanyError(true);
        }
        if(desc && describe_interest === ''){
            setDescError(true);
        }
        if((company && auxCompany === '') || (desc && describe_interest === '')){
            return ;
        }




        dispatch(actPostForm({...data,event_id:event.id}, (res) => {
            setResponse(res);
            setEmail(email);
            res.status === 409 ?
                setModal({
                    open: true,
                    message: '',
                    res,
                    title: language.verify_register_error,
                    handleClick: () => setModal({open: false})
                }) :
                setModal({
                    open: true,
                    message: '',
                    res,
                    title: language.verify_register_success,
                    handleClick: () => window.location.reload()
                })
        }))
    };




    const sendEmail = () => {
        if(email && response){
            const {data:{id}} = response;
            if(id){
                dispatch(actSendEmail({
                    id,
                    email,
                },()=>{
                    window.location.reload()
                }))
            }

        }

    };
    
    const openModal = (politics = false) => {
        politics ?
            setModal({
                title: terms.title_terms_and_condition,
                message: terms.design_terms_and_conditions,
                open: !modal.open,
                handleClick: () => setModal({open: false})
            }) :
            setModal({
                open: !modal.open,
                title: terms.title_personal_data_processing_policies,
                message: terms.design_personal_data_processing_policies,
                handleClick: () => setModal({open: false})
            })
    };

    const typeParticip = (data) => {
        const id = data.target.value;
        const aux = typeParticipations.find(f => f.value === id);

        if(aux.original_value){
            if (aux.original_value === '1' || aux.original_value === '3' || aux.original_value === '4') {
                setCompany(true)

            } else {
                setCompany(false);
                setValue('company', '', true)
            }
        }

    };

    const mainInterest = (data) => {
        const id = data.target.value;
        const aux = mainInterests.find(f => f.value === id);
        if (aux && aux.original_value && aux.original_value === '7') {
            setDesc(true)
        } else {
            setDesc(false)
            setValue('describe_interest', '', true)
        }
    }

    useEffect(() => {
        dispatch(actGetToken());
        dispatch(actGetDataForm({lang:'es',event_url:id}))
    }, [dispatch,id]);


    return {
        countries,
        languages,
        typeParticipations,
        mainInterests,
        colombiaKnows,
        tyc,
        language,
        desc,
        company,
        modal,
        changeLanguage,
        typeParticip,
        mainInterest,
        submitForm,
        openModal,
        sendEmail,
        isFetching,
        companyError,
        companyOnChange,
        descError,
        descOnChange,
        valEmail,
        setValEmail,
        lang,
        knowCountry,
        city,
        participationType,
        principalInterest,
        lan,
        footerTerms,
        country,
        terms,
        titles
    }
};
