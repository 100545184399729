import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

// Routes URL List
import {REGISTER_URL, VERIFY_URL, MAPA_URL} from "./RouterList";

// Screens
import FormScreen from '../screens/form';
import VerifyScreen from '../screens/verify';
import MapaScreen from '../screens/mapa'


function AppRouter() {
    return (
        <Router>
            <Switch>
                <Route exact path={`${REGISTER_URL}/:id`} component={FormScreen}/>
                <Route exact path={VERIFY_URL} component={VerifyScreen}/>
                <Route exact path={MAPA_URL} component={MapaScreen}/>
                <Redirect to={REGISTER_URL}/>
            </Switch>
        </Router>
    );
}

export default AppRouter;
