import styled from "styled-components"
import Dialog from '@material-ui/core/Dialog';


export const StyleModal = styled(Dialog).attrs({
    className: 'StyleModal'
})`
  .MuiDialog-paperWidthSm {
      max-width: 800px;
  }

  .MuiDialog-paper {
    background-color: #fdf5e9;
    overflow: visible;
    padding: 2rem;
    .MuiDialogContent-root{
      max-height: 450px;
      .MuiTypography-colorTextSecondary {
        color: #00076c;
        font-size: 14px;
        line-height: 1.25;
      }
    }
    .MuiDialogActions-root {
      margin: 0 auto;
    }
  }
  .MuiBackdrop-root {
    background-color: rgba(255, 74, 83, 0.85);
  }

  .MuiDialogTitle-root {
    padding: 1rem;
  }

  .MuiTypography-h6 {
    text-align: center;
    font-weight: bold;
    line-height: 1.25;
    color: #00076c;
    span, em {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.15;
      font-style: normal;
    }
  }

  @media screen and (max-width: 576px) {
    .MuiDialog-paper {
      padding: 1rem;
    }
  }
`

export const StyledWrapperButton = styled.div.attrs({
  className: 'StyledWrapperButton',
}) `
  display: flex;
`
