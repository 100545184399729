
// Components
import {Image} from '../../components/Image/Image';

//Assets
import imageBackground from "../../assets/img/bg-mosaico-registro-lg.png";
import imgBackgroundMobile from "../../assets/img/bg-mosaico-registro-pq.png";
import imageLogo from "../../assets/img/logo-marca-colombia.png";

//Hook
import {StyledWrapper} from '../form/form.styles';
import {useVerify} from "./hook";
import SpinnerLoadingIndicator from "../../components/SpinnerLoading";

function VerifyScreen() {

    const {isSuccess,language,isFetching} = useVerify();


    return (
        <div>
            <Image
                imageSrc={imageLogo}
                className="relative block mx-auto my-12 w-56 sm:w-auto"
            />
            <Image
                imageSrc={imageBackground}
                className="absolute w-full mt-12 hidden sm:block"
            />
            <Image
                imageSrc={imgBackgroundMobile}
                className="absolute w-full mt-12 block sm:hidden"
            />
            <StyledWrapper className="w-10/12 max-w-3xl mx-auto h-80 flex flex-row justify-items-center items-center">
                <div className="flex flex-col justify-items-center items-center mx-auto">
                    <h1 className="text-lg font-sansRegular text-secondary text-center leading-tight w-full">
                        {isSuccess && (
                            language.verify_email_success
                        )}
                        {!isSuccess && (
                            language.verify_email_error
                        )}
                    </h1>
                    {/*    <h2 className="text-base font-sansRegular text-secondary text-center leading-tight w-full">
                              {''}
                          </h2>*/}
                </div>
            </StyledWrapper>
            <SpinnerLoadingIndicator isFetching={isFetching}/>
        </div>
    )


}

export default VerifyScreen;
