export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';

export const GET_DATA_FORM = 'GET_DATA_FORM';
export const GET_DATA_FORM_SUCCESS = 'GET_DATA_FORM_SUCCESS';
export const GET_DATA_FORM_ERROR = 'GET_DATA_FORM_ERROR';
export const SET_LANG = 'SET_LANG';
export const SET_SPANISH = 'SET_SPANISH';
export const SET_ENGLISH = 'SET_ENGLISH';
export const SET_ITALIAN = 'SET_ITALIAN';
export const SET_FRENCH = 'SET_FRENCH';
export const SET_PORTUGUESE = 'SET_PORTUGUESE';
export const SET_RUSSIAN = 'SET_RUSSIAN';
export const SET_DEUTSCHE = 'SET_DEUTSCHE';
export const SET_CHINESE = 'SET_CHINESE';
export const SET_ARAB = 'SET_ARAB';

export const SET_LANG_FORM = 'SET_LANG_FORM';

export const POST_VERIFY_EMAIL = 'POST_VERIFY_EMAIL';
export const POST_VERIFY_EMAIL_SUCCESS = 'POST_VERIFY_EMAIL_SUCCESS';
export const POST_VERIFY_EMAIL_ERROR = 'POST_VERIFY_EMAIL_ERROR';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
