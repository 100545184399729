// import {Fragment} from 'react'
// import {useMapa} from './hook'

import {Image} from '../../components/Image/Image';

import imageBackground from "../../assets/img/bg-mosaico-registro-lg.png";
import imgBackgroundMobile from "../../assets/img/bg-mosaico-registro-pq.png";
import imageLogo from "../../assets/img/logo-marca-colombia.png";
import imageMap from "../../assets/img/Mapa-3C06-en-Fitur.jpg";
import { StyledWrapperMap } from './mapa.styles';

const MapaScreen = () => {

    // const {
    //     data
    // } = useMapa()

    return (
        <div>
            <Image
                imageSrc={imageLogo}
                className="relative block mx-auto my-12 w-56 sm:w-auto"
            />
            <Image
                imageSrc={imageBackground}
                className="absolute w-full mt-12 hidden sm:block"
            />
            <Image
                imageSrc={imgBackgroundMobile}
                className="absolute w-full mt-12 block sm:hidden"
            />
            <StyledWrapperMap className="w-10/12 max-w-2xl mx-auto flex flex-row justify-items-center items-center py-7">
                <Image
                    imageSrc={imageMap}
                    className="w-11/12 mx-auto"
                />
            </StyledWrapperMap>
        </div>
    )

}

export default MapaScreen
