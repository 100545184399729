import React from "react";
import PropTypes from "prop-types";

import { StyledImage } from "./Image.styles";

export const Image = ({ className, imageAlt, imageSrc }) => {
  return (
    <StyledImage
      loading="lazy"
      className={className}
      src={imageSrc}
      alt={imageAlt}
      title={imageAlt}
    >
    </StyledImage>
  )
}

Image.propTypes = {
  className: PropTypes.any,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
}

Image.defaultProps = {
  className: "Styledimage",
  imageAlt: "",
  imageSrc: "",
}


