import PropTypes from 'prop-types';

// Styles
import {ButtonDefaultStyle} from './styles';

function ButtonDefault(props) {

    const {text, type} = props;

    return (
        <ButtonDefaultStyle type={type} className="bg-blue-dark-fitur" {...props}>
            {text}
        </ButtonDefaultStyle>
    );
}

// PropTypes
ButtonDefault.propTypes = {
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
}

export default ButtonDefault;