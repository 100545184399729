import {
    GetToken,
    GetDataForm,
    PostForm,
    VerifyPost,
    SendEmail
} from '../../services'
import {
    GET_TOKEN,
    GET_TOKEN_SUCCESS,
    GET_TOKEN_ERROR,
    GET_DATA_FORM,
    GET_DATA_FORM_ERROR,
    GET_DATA_FORM_SUCCESS,
    SET_LANG_FORM,
    POST_VERIFY_EMAIL,
    POST_VERIFY_EMAIL_SUCCESS,
    POST_VERIFY_EMAIL_ERROR,
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_ERROR, SET_LANG
} from '../types';

export const actSetLanguage = (lang) => dispatch => {
    const direccion = document.getElementById('form_procolombia')
    const label_politics = document.getElementById('label-politics')
    const span_politics = document.getElementById('span-politics')
    const label_terms = document.getElementById('label-terms')
    const span_terms = document.getElementById('span-terms')
    direccion.dir = ''
    label_terms.style.paddingLeft = '35px'
    label_terms.style.paddingRight = '0'
    span_terms.style.right = '100%'
    label_politics.style.paddingLeft = '35px'
    label_politics.style.paddingRight = '0'
    span_politics.style.right = '100%'
    switch (lang) {
        case 'ar':
            direccion.dir = 'rtl'
            label_terms.style.paddingLeft = '0'
            label_terms.style.paddingRight = '35px'
            span_terms.style.right = '0'
            label_politics.style.paddingLeft = '0'
            label_politics.style.paddingRight = '35px'
            span_politics.style.right = '0'
            dispatch({
                type: SET_LANG,
                payload: lang
            });
            break;
        default:
            dispatch({
                type: SET_LANG,
                payload: lang
            })
            break;
    }
}

export const actLangForm = (lang) => dispatch => {
    dispatch({
        type: SET_LANG_FORM,
        payload: {
            ...lang,
            lang:lang.code
        }
    })
}

export const actGetToken = () => async dispatch => {
    dispatch({
        type: GET_TOKEN
    })
    await GetToken()
        .then(res => {
            dispatch({
                type: GET_TOKEN_SUCCESS,
                payload: res.data.token
            })
        }).catch(e => {
            dispatch({
                type: GET_TOKEN_ERROR,
                payload: e
            })
        })
}


export const actGetDataForm = ({lang,event_url}) => async (dispatch,getState) => {
    dispatch({
        type: GET_DATA_FORM
    });
    const state = getState();
    const {form} = state;
    try{
        const res = await GetDataForm({lang,event_url});
        const {config} = res.data;
        if(config && config.languages && !form.id){
            const lan = config.languages.find(f => f.code = 'es');
            if(lan){
                dispatch({
                    type:SET_LANG_FORM,
                    payload: {
                        ...lan,
                        lang:lan.code
                    }
                })
            }
        }
        dispatch({
            type: GET_DATA_FORM_SUCCESS,
            payload: res.data
        })
    }catch (e) {
        dispatch({
            type: GET_DATA_FORM_ERROR,
            error: e
        })
    }

};

export const actPostForm = (data, callback) => async () => {
    await PostForm({
        event_id: data.event_id,
        name: data.name,
        surname: data.surname,
        email: data.email,
        country_id: data.country_id,
        town: data.town,
        type_participation_id: data.type_participation_id,
        company: data.company,
        colombia_know_id: data.colombia_know_id,
        main_interest_id: data.main_interest_id,
        describe_interest: data.describe_interest,
        language_information_id: data.language_information_id,
        terms_and_conditions_use: data.terms,
        personal_data_policy: data.politics
    }).then(res => {
        if (callback) {
            callback(res)
        }
    }).catch(e => {
        if (e.response.status === 409 && callback) {
            callback(e.response)
        }
    })
};

export const actVerifyEmail = (data, callback) => async dispatch => {
    try{
        dispatch({
            type: POST_VERIFY_EMAIL
        });
         await VerifyPost(data);
        dispatch({
            type: POST_VERIFY_EMAIL_SUCCESS
        })
        if(callback){
            callback(true);
        }
    }catch (e) {
        dispatch({
            type: POST_VERIFY_EMAIL_ERROR
        })
        if(callback){
            callback(false);
        }
    }
};

export const actSendEmail = (data,callback) => async dispatch =>{
  try{
      dispatch({
          type: SEND_EMAIL
      });
      await SendEmail(data);
      dispatch({
          type:SEND_EMAIL_SUCCESS
      });
      if(callback){
          callback();
      }
  }  catch (e) {
      dispatch({
          type:SEND_EMAIL_ERROR
      });
  }
};
