// Inputs
import InputDefaultComponent from './default';
import InputSelectComponent from './select';
import InputCheckboxComponent from './checkbox';
import InputSelectLanguageComponent from './SelectLanguage'

export const Input = InputDefaultComponent;
export const Select = InputSelectComponent;
export const Checkbox = InputCheckboxComponent;
export const SelectLanguage = InputSelectLanguageComponent;

const useComponents = () => {
  return {
    Input,
    Select,
    Checkbox,
    SelectLanguage,
  };
};

export default useComponents;
