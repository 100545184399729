import styled from 'styled-components';

export const InputCheckboxStyle = styled.div`
  label {
    cursor: pointer;
    display: table;
    position: relative;
    margin: 0 auto 1rem;
    color: white;
    font-family: "RobotoMedium";
    font-size: 14px;
    line-height: 1.25;
    padding-left: 0px !important;
    p {
      display: inline;
      color: white;
      text-decoration: underline;
    }
    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0;
    }
    span {
      background-color: white;
      border: none;
      height: 25px;
      left: -5px;
      position: absolute;
      top: 50%;
      transform: translate(-100%, -50%);
      width: 25px;
      &:after {
        content: "";
        display: none;
        position: absolute;
      }
    }
  }
  label input:checked ~ span {
    background-color: #040071;
  }
  label input:checked ~ span:after {
    display: block;
  }
  label span:after {
    border: solid white;
    border-width: 0 3px 3px 0;
    height: 12px;
    left: 10px;
    top: 4px;
    transform: rotate(45deg);
    width: 5px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
  }
  @media screen and (max-width: 576px) {
    label {
      padding-left: 25px !important;
      margin: 0 0 1rem;
      span {
        border: none;
        height: 20px;
        left: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: 20px;
      }
    }

  }
`;
