import {createSelector} from 'reselect';

export const useVerifySelector = () => {
    return createSelector(
        state => state.translate,
        state => state.verifyEmail,
        (translate) => translate.language
    );
};

export const isFetchingSelector = createSelector(
    state => state.verifyEmail,
    (verifyEmail) => verifyEmail.isFetching
);
