import React from 'react'
import PropTypes from 'prop-types';
import {SelectDefaultStyle} from './style'


const SelectLanguage = (props) => {
    const {changeLanguage, lan} = props;
    const selectLanguage = ({code,lan}) => {
        changeLanguage(lan);
        const btn = document.getElementById('btnTitleLanguage');
        const textLanguage = document.getElementById(code);
        btn.textContent = textLanguage.textContent;
    };


    return (
        <SelectDefaultStyle>
            <button id="btnTitleLanguage" className="dropbtn">ESPAÑOL</button>
            <div className="dropdown-content">
                {lan.map((m,i) => {
                    return (
                        <div lang={m.code} id={m.code} key={`${i}-${m.code}`} onClick={() => selectLanguage({code:m.code,lan:m})}>{m.name}</div>
                    )
                })}
            </div>
        </SelectDefaultStyle>

    )
}

SelectLanguage.propTypes = {
    changeLanguage: PropTypes.func.isRequired,
    lan: PropTypes.array
}

export default SelectLanguage
