// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form'
// Styles
import { InputCheckboxStyle } from './styles';

function InputCheckbox(props) {

    const {type, name, text, textLink, control, id, lang, required = false, onClick} = props;



    return (
        <InputCheckboxStyle>
            <label className="my-5" id={`label-${id}`} lang={lang}>
                {text} {textLink ? <p onClick={onClick}>{textLink}</p> : null}
                <Controller
                    control={control}
                    name={name}
                    rules={{required: required}}
                    defaultValue={false}
                    render={({
                        field:{onChange}
                            })=>(
                        <input
                            type={type}
                            name={name}
                            onChange={onChange}
                        />
                    )}

                />
                <span id={`span-${id}`}></span>
            </label>
        </InputCheckboxStyle>
    );
}

// PropTypes
InputCheckbox.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textLink: PropTypes.string,
    url: PropTypes.string,
    lang: PropTypes.string
}


export default InputCheckbox;