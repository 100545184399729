import PropTypes from 'prop-types';

// Styles
import {Controller} from "react-hook-form";
import {TextField} from "@material-ui/core";
import React from "react";

const InputDefault = ((props) => {
    let aux = null;
    const {
        label,
        name,
        required = false,
        defaultValue = "",
        control,
        onCustomChange,
        rules,
    } = props;

    const defaultOnchange = (value) => {
        if (aux) {
            aux(value.target.value);
        }
        onCustomChange(value.target.value);
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                ...rules,
                required: required
            }}
            defaultValue={defaultValue}
            render={({field: {onChange, value}}) => {
                aux = onChange;
                return (
                    <TextField value={value} onChange={defaultOnchange} name={name} label={label}/>
                )
            }}
        />
    );
})

// PropTypes
InputDefault.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    onCustomChange: PropTypes.func,
    rules: PropTypes.any,
};

InputDefault.defaultProps = {
    onCustomChange: () => {
    },
    defaultValue: "",
    rules: {
        required: false
    }
};


export default InputDefault;
