import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Button} from '../buttons'
import ReactHtmlParse from 'react-html-parser';
import {
    StyledWrapperButton,
    StyleModal
} from './Modal.styles'
import React from "react";
import { useFormApp } from '../../screens/form/hook';
import parse from "html-react-parser";




const Modal = (props) => {

    const {language} = useFormApp();

    const {open, message, title, handleClick, data, sendOption} = props;

    return (
        <StyleModal open={open}>
            <DialogTitle>
                { title && ReactHtmlParse(title)}
            </DialogTitle>
            <DialogContent

            >
                {message && parse(message)}
            </DialogContent>
            <DialogActions>

                {data && data.status === 409 ?
                    (
                        <StyledWrapperButton>
                            <Button
                                type={'button'}
                                onClick={sendOption}
                                text={language.button_text}
                                className="bg-secondary text-yellow uppercase font-sansBold text-base py-1 w-32 mr-2"
                            />
                            <Button
                                type={'button'}
                                onClick={() => handleClick()}
                                text={language.button_close}
                                className="bg-secondary text-yellow uppercase font-sansBold text-base py-1 w-32"
                            />
                        </StyledWrapperButton>
                    )
                    :
                    (
                        <Button
                            type={'button'}
                            onClick={() => handleClick()}
                            text={language.button_close}
                            className="bg-secondary text-yellow uppercase font-sansBold text-base py-1 w-32 bottom-0 absolute"
                        />
                    )
                }

            </DialogActions>
        </StyleModal>
    )
}

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
     message: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    data: PropTypes.any,
    sendOption: PropTypes.func
};

Modal.defaultProps = {
    open: true,
    message: '',
    handleClick: () => {
    },
    data: null,
    sendOption: ()=> {

    }
};


export default Modal;
