import createReducer from '../../createReducer'
import {language} from '../../languages'
import {
    GET_TOKEN,
    GET_TOKEN_SUCCESS,
    GET_TOKEN_ERROR,
    GET_DATA_FORM,
    GET_DATA_FORM_ERROR,
    GET_DATA_FORM_SUCCESS,
    SET_LANG_FORM,
    POST_VERIFY_EMAIL,
    POST_VERIFY_EMAIL_SUCCESS,
    POST_VERIFY_EMAIL_ERROR,
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_ERROR, SET_LANG
} from '../../../actions/types'


export const form = createReducer({
    lang: language.es.lang
}, {
    [SET_LANG_FORM](state, action) {
        return {
            ...state,
            ...action.payload
        }
    }
})

export const dataForm = createReducer({
    config: {
        event: {
            id: 0
        },
        footerTerms:[],
        inputs:{
            city:false,
            knowCountry:false,
            participationType:false,
            participalInterest:false
        },
        languages:[]
    },
    message: {
        colombiaKnows:[],
        countries:[],
        languages:[],
        mainInterests:[],
        termAndConditions: [],
        typeParticipations:[],
        url:''
    }

}, {
    [GET_DATA_FORM](state, action) {
        return {
            ...state,
            ...action.payload
        }
    },
    [GET_DATA_FORM_SUCCESS](state, action) {
        return {
            ...state,
            ...action.payload
        }
    },
    [GET_DATA_FORM_ERROR](state, action) {
        return {
            ...state,
            error: action.payload
        }
    }
});

export const token = createReducer({
    token: ''
}, {
    [GET_TOKEN](state, action) {
        return {
            ...state,
            ...action.payload
        }
    },
    [GET_TOKEN_SUCCESS](state, action) {
        return {
            ...state,
            token: action.payload
        }
    },
    [GET_TOKEN_ERROR](state, action) {
        return {
            ...state,
            error: action.payload
        }
    }
})

export const translate = createReducer({
    language: language.es
}, {
    [SET_LANG](state,action){
        return {
            ...state,
            language:language[action.payload]
        }
    }
});

export const verifyEmail = createReducer({
    isFetching: false,
    isSuccess: false,
}, {
    [POST_VERIFY_EMAIL](state) {
        return {
            ...state,
            isFetching: true
        }
    },
    [POST_VERIFY_EMAIL_SUCCESS](state) {
        return {
            ...state,
            isFetching: false,
            isSuccess: true
        }
    },
    [POST_VERIFY_EMAIL_ERROR](state) {
        return {
            ...state,
            isFetching: false,
            isSuccess: false
        }
    }
});

export const resendEmail = createReducer({
    isFetching: false,
}, {
    [SEND_EMAIL](state) {
        return {
            ...state,
            isFetching: true
        }
    },
    [SEND_EMAIL_SUCCESS](state) {
        return {
            ...state,
            isFetching: false,
        }
    },
    [SEND_EMAIL_ERROR](state) {
        return {
            ...state,
            isFetching: false,
        }
    }
});
