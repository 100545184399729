import {useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {actVerifyEmail} from "../../../actions/form";
import {useVerifySelector,isFetchingSelector} from "../selector";

export const useVerify = () => {
    const dispatch = useDispatch();
    const {id, token} = useParams();
    const [isSuccess, setIsSuccess] = useState();
    const query = useVerifySelector();
    const language = useSelector(query);
    const isFetching = useSelector(isFetchingSelector);

    const verifyCallback = (data) => setIsSuccess(data);

    useEffect(() => {
        if (id && token) {
            dispatch(actVerifyEmail({id, token}, verifyCallback));
        }
    }, [id, token, dispatch])

    return {
        isSuccess,
        language,
        isFetching
    }

};
