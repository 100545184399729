export const language = {
    es: {
        lang: 'es',
        title: 'Regístrate para darte una cálida bienvenida en',
        subtitle: 'nuestra casa Colombia en FITUR 2021',
        select: '- Seleccionar -',
        name: 'Nombre',
        lastname: 'Apellido',
        email: 'Email',
        country: 'País',
        city: 'Ciudad',
        participation: 'Tipo de participación',
        company: 'Empresa',
        know: '¿Conoce Colombia?',
        interest: 'Principal interés',
        interest_placeholder: 'Describa aquí su interés',
        language: '¿En qué idioma desea recibir información?',
        term: 'He leído y acepto los',
        link_term: 'Términos y condiciones de uso del portal Colombia.Travel',
        politics: 'He leído y acepto la',
        link_politics: 'Política y tratamiento de datos personales',
        mail_valid: 'Email invalido',
        text_error: 'Este campo es requerido',
        button_text: 'Enviar',
        button_close: 'Cerrar',
        verify_register_success:'Gracias por registrarte.<br /> <br /> <span>Recibirá su código QR al mail registrado</span>',
        verify_register_error:'La dirección de correo electrónico ya se encuentra registrado. <br /> <br /> <span>Si has perdido o borrado tu acreditación QR, pulsa ENVIAR <br /> y recibirás un nuevo correo. <br /></span> <em>De lo contrario, introduce una dirección de correo válida diferente.</em>',
        verify_email_success:'Gracias por registrarte',
        verify_email_error:'Este correo ya fue verificado. Si deseas una copia de tu passbook hazlo a través del formulario de registro'
    },
    en: {
        lang: 'en',
        title: 'Discover Colombia, the country with wide-open doors!',
        subtitle: 'Register and get your pass for our booth',
        select: '- To select -',
        name: 'Name',
        lastname: 'Last name',
        email: 'E-mail',
        country: 'Country',
        city: 'City',
        participation: 'Profile',
        company: 'Company',
        know: 'Do you know Colombia?',
        interest: 'What are you interested in?',
        interest_placeholder: 'Describe your interest here',
        language: 'Preferred language of contact',
        term: 'I have read and accept the',
        link_term: 'Terms and Conditions of use of the portal',
        politics: 'I have read and accept the',
        link_politics: 'personal data processing policy.',
        mail_valid: 'Invalid e-mail',
        text_error: "This is required",
        button_text: 'Send',
        button_close: 'Close',
        verify_register_success:'Thank you for signing up.<br /> <br /> <span>You will receive your QR code to the registered mail</span>',
        verify_register_error:'The email address is already registered. <br /> <br /> <span>If you have lost or deleted your QR accreditation, press SEND <br /> and you will receive a new email. <br /></span> <em>Otherwise, please enter a different valid email address.</em>',
        verify_email_success:'Thank you for signing up.',
        verify_email_error:'This email has already been verified. If you want a copy of your passbook, do it through the registration form'
    },
    it: {
        lang: 'it',
        title: 'Vuoi unirti a noi e vivere esperienze indimenticabili? Iscriviti.',
        subtitle: 'Compila il seguente modulo e ottieni il tuo pass per la Colombia. Godere.',
        select: '- Selezionare -',
        name: 'Nome',
        lastname: 'Cognome',
        email: 'E-mail',
        country: 'Nazione',
        city: 'Cittadina',
        participation: 'Tipo di partecipazione',
        company: 'Attività commerciale',
        know: 'Conosci la Colombia?',
        interest: 'Interesse principale',
        interest_placeholder: '- Descrivi qui il tuo interesse -',
        language: 'In che lingua vuoi ricevere le informazioni?',
        term: 'Ho letto e accetto i',
        link_term: 'termini e le condizioni di utilizzo',
        politics: 'Ho letto e accetto',
        link_politics: 'l\'informativa sul trattamento dei dati personali',
        text_error: 'Questo campo è obbligatorio',
        button_text: 'Spedire'
    },
    fr: {
        lang: 'fr',
        title: 'Vous souhaitez vous joindre à nous et vivre des expériences inoubliables? S\'inscrire.',
        subtitle: 'Remplissez le formulaire suivant et obtenez votre pass pour la Colombie. Prendre plaisir.',
        select: '- Pour sélectionner -',
        name: 'Nom',
        lastname: 'Le nom',
        email: 'E-mail\n',
        country: 'Pays',
        city: 'Ville',
        participation: 'Type de participation',
        company: 'Entreprise',
        know: 'Connaissez-vous la Colombie?',
        interest: 'Intérêt principal',
        interest_placeholder: '- Décrivez votre intérêt ici -',
        language: 'Dans quelle langue souhaitez-vous recevoir des informations?',
        term: 'J\'ai lu et j\'accepte les ',
        link_term: 'conditions générales d\'utilisation',
        politics: 'J\'ai lu et j\'accepte la ',
        link_politics: 'politique de traitement des données personnelles',
        text_error: 'Ce champ est requis',
        button_text: 'Envoyer',
    },
    pt: {
        lang: 'pt',
        title: 'Quer se juntar a nós e desfrutar de experiências inesquecíveis? Cadastre-se.',
        subtitle: 'Preencha o seguinte formulário e obtenha seu passe para a Colômbia. Apreciar.',
        select: '- Selecionar -',
        name: 'Nome',
        lastname: 'Sobrenome',
        email: 'E-mail',
        country: 'País',
        city: 'Cidade',
        participation: 'Tipo de participação',
        company: 'O negócio',
        know: 'Você conhece a colômbia?',
        interest: 'Interesse principal',
        interest_placeholder: '- Descreva o seu interesse aqui -',
        language: 'Em que idioma você deseja receber informações?',
        term: 'Li e aceito os',
        link_term: 'termos e condições de uso',
        politics: 'Li e aceito a',
        link_politics: 'política de tratamento de dados pessoais',
        text_error: 'Este campo é obrigatório',
        button_text: 'Mandar'
    },
    ru: {
        lang: 'ru',
        title: '¿Хотите присоединиться к нам и получить незабываемые впечатления? Зарегистрироваться.',
        subtitle: 'Заполните следующую форму и получите пропуск в Колумбию. Наслаждаться.',
        select: '- Выбирать -',
        name: 'Имя',
        lastname: 'Фамилия',
        email: 'Электронная почта',
        country: 'Страна',
        city: 'Городок',
        participation: 'Тип участия',
        company: 'Бизнес',
        know: 'Вы знаете Колумбию?',
        interest: 'Главный интерес',
        interest_placeholder: '- Опишите здесь свой интерес -',
        language: 'На каком языке вы хотите получать информацию?',
        term: 'Я прочитал и принимаю',
        link_term: 'условия использования',
        politics: 'Я прочитал и принимаю',
        link_politics: 'политику обработки персональных данных',
        text_error: 'Это поле обязательно к заполнению',
        button_text: 'послать'
    },
    de: {
        lang: 'de',
        title: 'Möchten Sie mit uns zusammentreffen und unvergessliche Erlebnisse erleben? Registrieren.',
        subtitle: 'Füllen Sie das untenstehende Formular aus und erhalten Sie Ihren Pass nach Kolumbien. Genießen.',
        select: '- Auswählen -',
        name: 'Namen',
        lastname: 'Familiennamen',
        email: 'E-Mail',
        country: 'Land',
        city: 'Stadt',
        participation: 'Art der Teilnahme',
        company: 'Unternehmen',
        know: 'Kennen Sie Kolumbien?',
        interest: 'Hauptinteresse',
        interest_placeholder: '- Beschreiben Sie hier Ihr Interesse -',
        language: 'In welcher Sprache möchten Sie die Informationen erhalten?',
        term: 'Ich habe die',
        link_term: 'Nutzungsbedingungen gelesen und akzeptiere sie',
        politics: 'Ich habe die',
        link_politics: 'Richtlinien zur Behandlung personenbezogener Daten gelesen und akzeptiere sie',
        text_error: 'Dieses Feld wird benötigt',
        button_text: 'Senden'
    },
    zh: {
        lang: 'zh',
        title: '您想加入我們並享受難忘的經歷嗎？註冊。',
        subtitle: '填寫以下表格並獲得通行證。享受。',
        select: '- 選擇 -',
        name: '名稱',
        lastname: '姓',
        email: '電子郵件',
        country: '國家',
        city: '城市',
        participation: '參加類型',
        company: '公司',
        know: '你知道哥倫比亞嗎？',
        interest: '主要興趣',
        interest_placeholder: '- 在這裡描述您的興趣 -',
        language: '您想用哪種語言接收信息？',
        term: '我已閱讀並接受',
        link_term: '使用條款和條件',
        politics: '我已閱讀並接受',
        link_politics: '個人數據處理政策',
        text_error: "這是必需的",
        button_text: '發送'
    },
    ar: {
        lang: 'ar',
        title: 'هل تريد الانضمام إلينا والاستمتاع بتجارب لا تُنسى؟ اشتراك.',
        subtitle: 'املأ النموذج التالي واحصل على تصريح دخولك إلى كولومبيا. يتمتع.',
        select: '- لتحديد -',
        name: 'اسم',
        lastname: 'الألقاب',
        email: 'بريد إلكتروني',
        country: 'دولة',
        city: 'قرية',
        participation: 'نوع المشاركة',
        company: 'اعمال',
        know: 'هل تعرف كولومبيا',
        interest: 'الاهتمام الرئيسي',
        interest_placeholder: '- صِف اهتمامك هنا -',
        language: 'بأي لغة تريد أن تتلقى المعلومات؟',
        term: 'لقد قرأت وقبلت',
        link_term: 'شروط وأحكام الاستخدام',
        politics: 'لقد قرأت ووافقت على',
        link_politics: 'سياسة معالجة البيانات الشخصية',
        text_error: 'هذه الخانة مطلوبه',
        button_text: 'إرسال'
    }
}

