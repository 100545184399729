import {trackPromise} from 'react-promise-tracker';
import {
    getToken,
    getDataForm,
    postForm,
    verifyForm,
    sendEmail,
    formConfig
} from '../provider/form'

export const GetToken = () => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await getToken())
        } catch (e) {
            reject(e)
        }
    })
}

export const GetDataForm = ({lang,event_url}) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await trackPromise(getDataForm({lang,event_url})))
        } catch (e) {
            reject(e)
        }
    })
}

export const PostForm = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await trackPromise(postForm(data)))
        } catch (e) {
            reject(e)
        }
    })
};

export const VerifyPost = (data) =>{
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await trackPromise(verifyForm(data)))
        } catch (e) {
            reject(e)
        }
    })
};

export const SendEmail = (data) =>{
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await trackPromise(sendEmail(data)))
        } catch (e) {
            reject(e)
        }
    })
};

export const FormConfig = ({event_url}) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await trackPromise(formConfig({event_url})))
        } catch (e) {
            reject(e)
        }
    })
}
