import styled from "styled-components";
import tw from "tailwind.macro";

export const StyledWrapper = styled.div.attrs({
  className: "StyledWrapper",
})`
  ${tw `bg-yellow-100 p-6 pb-12 relative`}
`

export const StyledGrid = styled.div.attrs({
  className: "StyledGrid mt-12",
})`
  ${tw `flex flex-wrap`}
`

export const StyledCol = styled.div.attrs({
  className: "StyledCol",
})`
  ${tw `w-full lg:w-1/2 py-1 sm:px-4 pb-3`}
`

export const StyledTerms = styled.div.attrs({
  className: "StyledTerms",
})`
  ${tw `text-left sm:text-center px-10`}
`