import {Fragment} from 'react'
import axios from 'axios'
import {useSelector} from 'react-redux'
import {selectorToken} from "./selectors";

export const axiosForm = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

export const interceptorHandler = (WrappedComponent) => props => {
    const token = useSelector(selectorToken)
    axiosForm.defaults.headers = {
        Authorization: `Bearer ${token}`
    };
    return(
        <Fragment>
            <WrappedComponent props={props}/>
        </Fragment>
    )
}
